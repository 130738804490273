/**
 * angular-fancy-modal - the definitive modal/popup/dialog solution for AngularJS.
 * @author Alessandro Arnodo
 * @url http://alessandro.arnodo.net
 * @version v0.1.4
 * @link https://github.com/vesparny/angular-fancy-modal
 * @license MIT
 */
body.fancymodal-open {
  overflow: hidden; }

.fancymodal,
.fancymodal *,
.fancymodal *:before,
.fancymodal *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.fancymodal {
  padding-top: 120px;
  padding-bottom: 160px;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  padding-left: 8px;
  padding-right: 8px; }
  .fancymodal .fancymodal-overlay {
    -webkit-backface-visibility: hidden;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .fancymodal .fancymodal-content {
    max-width: 500px;
    background: #fff;
    -webkit-backface-visibility: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 2em 1em;
    border-radius: 5px;
    background: #eee;
    color: #000;
    position: relative;
    margin: 0 auto; }
  .fancymodal .fancymodal-close {
    border-radius: 3px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
  .fancymodal .fancymodal-close:before {
    border-radius: 3px;
    position: absolute;
    content: "\00D7";
    font-size: 26px;
    line-height: 30px;
    height: 30px;
    width: 30px;
    text-align: center;
    top: 3px;
    right: 3px;
    color: #ccc; }
  .fancymodal .fancymodal-close:hover:before,
  .fancymodal .fancymodal-close:active:before {
    color: #777;
    background: #e0e0e0; }
  .fancymodal .fancymodal-content-opening {
    -webkit-animation: fancymodal-in 0.5s;
    animation: fancymodal-in 0.5s; }
  .fancymodal .fancymodal-content-closing {
    -webkit-animation: fancymodal-out 0.5s;
    animation: fancymodal-out 0.5s; }
  .fancymodal .fancymodal-overlay-opening {
    -webkit-animation: fancymodal-fadeIn 0.5s;
    animation: fancymodal-fadeIn 0.5s; }
  .fancymodal .fancymodal-overlay-closing {
    -webkit-animation: fancymodal-fadeOut 0.5s;
    animation: fancymodal-fadeOut 0.5s; }

@-webkit-keyframes fancymodal-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fancymodal-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fancymodal-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); } }

@keyframes fancymodal-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); } }

@-webkit-keyframes fancymodal-fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fancymodal-fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fancymodal-fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fancymodal-fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }
