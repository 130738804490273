.dropdown-menu > li > label {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}
.dropdown-menu > li > label:hover,
.dropdown-menu > li > label:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu > li > input:checked ~ label,
.dropdown-menu > li > input:checked ~ label:hover,
.dropdown-menu > li > input:checked ~ label:focus,
.dropdown-menu > .active > label,
.dropdown-menu > .active > label:hover,
.dropdown-menu > .active > label:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #428bca;
}
.dropdown-menu > li > input[disabled] ~ label,
.dropdown-menu > li > input[disabled] ~ label:hover,
.dropdown-menu > li > input[disabled] ~ label:focus,
.dropdown-menu > .disabled > label,
.dropdown-menu > .disabled > label:hover,
.dropdown-menu > .disabled > label:focus {
  color: #999999;
}
.dropdown-menu > li > input[disabled] ~ label:hover,
.dropdown-menu > li > input[disabled] ~ label:focus,
.dropdown-menu > .disabled > label:hover,
.dropdown-menu > .disabled > label:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.dropdown-menu > li > label {
  margin-bottom: 0;
  cursor: pointer;
}
.dropdown-menu > li > input[type="radio"],
.dropdown-menu > li > input[type="checkbox"] {
  display: none;
  position: absolute;
  top: -9999em;
  left: -9999em;
}
.dropdown-menu > li > label:focus,
.dropdown-menu > li > input:focus ~ label {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu.pull-top {
  bottom: 100%;
  top: auto;
  margin: 0 0 2px;
  -webkit-box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-menu.pull-center {
  right: 50%;
  left: auto;
}
.dropdown-menu.pull-middle {
  right: 100%;
  margin: 0 2px 0 0;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
  left: auto;
}
.dropdown-menu.pull-middle.pull-right {
  right: auto;
  left: 100%;
  margin: 0 0 0 2px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
}
.dropdown-menu.pull-middle.pull-center {
  right: 50%;
  margin: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.dropdown-menu.bullet {
  margin-top: 8px;
}
.dropdown-menu.bullet:before {
  width: 0;
  height: 0;
  content: '';
  display: inline-block;
  position: absolute;
  border-color: transparent;
  border-style: solid;
  -webkit-transform: rotate(360deg);
  border-width: 0 7px 7px;
  border-bottom-color: #cccccc;
  border-bottom-color: rgba(0, 0, 0, 0.15);
  top: -7px;
  left: 9px;
}
.dropdown-menu.bullet:after {
  width: 0;
  height: 0;
  content: '';
  display: inline-block;
  position: absolute;
  border-color: transparent;
  border-style: solid;
  -webkit-transform: rotate(360deg);
  border-width: 0 6px 6px;
  border-bottom-color: #ffffff;
  top: -6px;
  left: 10px;
}
.dropdown-menu.bullet.pull-right:before {
  left: auto;
  right: 9px;
}
.dropdown-menu.bullet.pull-right:after {
  left: auto;
  right: 10px;
}
.dropdown-menu.bullet.pull-top {
  margin-top: 0;
  margin-bottom: 8px;
}
.dropdown-menu.bullet.pull-top:before {
  top: auto;
  bottom: -7px;
  border-bottom-width: 0;
  border-top-width: 7px;
  border-top-color: #cccccc;
  border-top-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu.bullet.pull-top:after {
  top: auto;
  bottom: -6px;
  border-bottom: none;
  border-top-width: 6px;
  border-top-color: #ffffff;
}
.dropdown-menu.bullet.pull-center:before {
  left: auto;
  right: 50%;
  margin-right: -7px;
}
.dropdown-menu.bullet.pull-center:after {
  left: auto;
  right: 50%;
  margin-right: -6px;
}
.dropdown-menu.bullet.pull-middle {
  margin-right: 8px;
}
.dropdown-menu.bullet.pull-middle:before {
  top: 50%;
  left: 100%;
  right: auto;
  margin-top: -7px;
  border-right-width: 0;
  border-bottom-color: transparent;
  border-top-width: 7px;
  border-left-color: #cccccc;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu.bullet.pull-middle:after {
  top: 50%;
  left: 100%;
  right: auto;
  margin-top: -6px;
  border-right-width: 0;
  border-bottom-color: transparent;
  border-top-width: 6px;
  border-left-color: #ffffff;
}
.dropdown-menu.bullet.pull-middle.pull-right {
  margin-right: 0;
  margin-left: 8px;
}
.dropdown-menu.bullet.pull-middle.pull-right:before {
  left: -7px;
  border-left-width: 0;
  border-right-width: 7px;
  border-right-color: #cccccc;
  border-right-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu.bullet.pull-middle.pull-right:after {
  left: -6px;
  border-left-width: 0;
  border-right-width: 6px;
  border-right-color: #ffffff;
}
.dropdown-menu.bullet.pull-middle.pull-center {
  margin-left: 0;
  margin-right: 0;
}
.dropdown-menu.bullet.pull-middle.pull-center:before {
  border: none;
  display: none;
}
.dropdown-menu.bullet.pull-middle.pull-center:after {
  border: none;
  display: none;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-top-left-radius: 0;
}
.dropdown-submenu > a:before {
  display: block;
  float: right;
  width: 0;
  height: 0;
  content: "";
  margin-top: 6px;
  margin-right: -8px;
  border-width: 4px 0 4px 4px;
  border-style: solid;
  border-left-style: dashed;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
@media (max-width: 767px) {
  .navbar-nav .dropdown-submenu > a:before {
    margin-top: 8px;
    border-color: inherit;
    border-style: solid;
    border-width: 4px 4px 0;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  .navbar-nav .dropdown-submenu > a {
    padding-left: 40px;
  }
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > a,
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > label {
    padding-left: 35px;
  }
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > a,
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > label {
    padding-left: 45px;
  }
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > a,
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > label {
    padding-left: 55px;
  }
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > a,
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > label {
    padding-left: 65px;
  }
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > a,
  .navbar-nav > .open > .dropdown-menu > .dropdown-submenu > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > .dropdown-menu > li > label {
    padding-left: 75px;
  }
}
.navbar-default .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a,
.navbar-default .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a:hover,
.navbar-default .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a:focus {
  background-color: #e7e7e7;
  color: #555555;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a:before {
    border-top-color: #555555;
  }
}
.navbar-inverse .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a,
.navbar-inverse .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a:hover,
.navbar-inverse .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a:focus {
  background-color: #080808;
  color: #ffffff;
}
@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open > .dropdown-menu > .dropdown-submenu.open > a:before {
    border-top-color: #ffffff;
  }
}
